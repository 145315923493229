import React from 'react'
import { AlertRec } from '../StdLib'
import AlternatingSections from '../Components/Sections/Sections'

export default function Items() {

  // AlertRec(_AccRecs, 'ABOUT PAGE ******************** NEAT _AccRec in main prgb**********************')
  return (
    <>
      <div>


        <AlternatingSections/>


      </div>
    </>
  )
}
