import React from 'react'
import { AlertRec } from '../StdLib'

export default function About() {

  // AlertRec(_AccRecs, 'ABOUT PAGE ******************** NEAT _AccRec in main prgb**********************')
  return (
    <>
      <div>
        <p className='text-2xl text-center'>Everything About Us is there</p>

        <div className='mx-auto w-full max-w-4xl h-[400px] rounded-xl shadow-2xl overflow-hidden'>
          <img className='w-full h-full object-fill' src={'/Images/UnderConstruction/UC-5.png'} alt={''} />
        </div>
      </div>
    </>
  )
}
